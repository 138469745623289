<template>
    <auth-layout>
        <auth-form :action="action" :callback="submit">
            <template #header>
                <h1>{{ $t('auth_login.title') }}</h1>
                <p>
                    {{ $t('auth_login.subtitle') }}
                    <router-link :to="{ name: 'auth-registration' }">{{ $t('auth_login.registration') }}</router-link>
                </p>
            </template>

            <validation-provider name="email" rules="required|email" v-slot="{ errors, reset }" slim>
                <input-group :label="$t('auth_login.email.label')" :errors="errors">
                    <input-component type="email" v-model="email" :placeholder="$t('auth_login.email.placeholder')" @input="reset" @mousedown.stop />
                </input-group>
            </validation-provider>

            <validation-provider name="password" rules="required|min:8" v-slot="{ errors, reset }" slim>
                <input-group :label="$t('auth_login.password.label')" :errors="errors">
                    <password-input v-model="password" :placeholder="$t('auth_login.password.placeholder')" @input="reset" @mousedown.stop />
                </input-group>
            </validation-provider>

            <router-link class="forgot-password" :to="{ name: 'auth-new-password' }">{{ $t('auth_login.forgot_password') }}</router-link>

            <template #button>
                {{ $t('auth_login.submit_button') }}
                <arrow-right-icon />
            </template>
        </auth-form>
    </auth-layout>
</template>

<script>
    import { DateTime } from 'luxon';
    import { ValidationProvider } from 'vee-validate';
    import AuthForm from '@/components/auth/AuthForm';
    import ArrowRightIcon from '@/components/icons/ArrowRightIcon';
    import InputComponent from '@/components/inputs/InputComponent';
    import InputGroup from '@/components/inputs/InputGroup';
    import PasswordInput from '@/components/inputs/PasswordInput';
    import Toast from '@/components/Toast';
    import AuthLayout from '@/pages/layout/AuthLayout';
    import { requestLoginCode } from '@/services/skinsPaymentApi';
    import { LOGIN_CODE_TIMEOUT } from '@/services/skinsPaymentApi/constants/common';

    export default {
        name: 'Login',
        components: {
            ValidationProvider,
            AuthForm,
            ArrowRightIcon,
            InputComponent,
            InputGroup,
            PasswordInput,
            AuthLayout,
        },
        computed: {
            action() {
                return 'user-cabinet-api/auth/request-login-code';
            },
        },
        methods: {
            submit(recaptchaToken) {
                return requestLoginCode(this.email, this.password, recaptchaToken).then(() => {
                    sessionStorage.setItem('AUTH_LOGIN_EMAIL', this.email);
                    sessionStorage.setItem('AUTH_LOGIN_PASSWORD', this.password);
                    sessionStorage.setItem('AUTH_LOGIN_TIMER_END', DateTime.now().plus({ seconds: LOGIN_CODE_TIMEOUT }).toUnixInteger());

                    this.$toast({
                        component: Toast,
                        props: {
                            type: 'success',
                            text: this.$t('auth_login.toasts.submit_success'),
                        },
                    });

                    this.$router.push({ name: 'auth-login-code' });
                });
            },
        },
        data() {
            return {
                email: '',
                password: '',
            };
        },
    };
</script>

<style lang="scss" scoped>
    .forgot-password {
        margin-top: 1rem;
        align-self: flex-end;
    }
</style>
